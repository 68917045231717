import React from "react"
import Layout from "../layout"
import { Container } from "reactstrap"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import MySwiper from "../../components/swiper/swiper"

const PartnersClient = ({ params }) => {
  return (
    <div className="container section-padding">
      <div className="section-heading text-center">
        {/* <h6 className="font-weight-bold text-uppercase flair">What's Your Goal?</h6> */}
        <h2 className="mt-0 mb-5">Trusted Partners And Clientele</h2>
      </div>
      <div className="container d-inline d-lg-flex justify-content-between">
        <div>
          <div className="review-item text-center my-2">
            <StaticImage src="../../images/assets/sa-logo.png" />
          </div>
        </div>
        <div>
          <div className="review-item text-center my-2">
            <StaticImage src="../../images/assets/om-logo.png" />
          </div>
        </div>
        <div>
          {" "}
          <div className="review-item text-center my-2">
            <StaticImage src="../../images/assets/csl-logo.png" />
          </div>
        </div>
        <div>
          <div className="review-item text-center my-2">
            <StaticImage src="../../images/assets/lac-logo.png" />
          </div>
        </div>
        <div>
          <div className="review-item text-center my-2">
            <StaticImage src="../../images/assets/vpe-logo.png" />
          </div>
        </div>

        <div>
          {" "}
          <div className="review-item text-center my-2">
            <StaticImage src="../../images/assets/nyj-logo.png" />
          </div>
        </div>
        <div>
          {" "}
          <div className="review-item text-center my-2">
            <StaticImage src="../../images/assets/s-logo.png" />
          </div>
        </div>
        <MySwiper {...params} slidesperview={7}>
          {/* <div className="row justify-content-center">
            <div className="col mx-auto">
              <div className="pt-5">
                <div className="review-item text-center">
                  <StaticImage src="../../images/assets/sa-logo.png" />
                </div>
              </div>
            </div>
            <div className="col mx-auto">
              <div className="pt-5">
                <div className="review-item text-center">
                  <StaticImage src="../../images/assets/om-logo.png" />
                </div>
              </div>
            </div>
            <div className="col mx-auto">
              <div className="pt-5">
                <div className="review-item text-center">
                  <StaticImage src="../../images/assets/csl-logo.png" />
                </div>
              </div>
            </div>
            <div className="col mx-auto">
              <div className="pt-5">
                <div className="review-item text-center">
                  <StaticImage src="../../images/assets/lac-logo.png" />
                </div>
              </div>
            </div>
            <div className="col mx-auto">
              <div className="pt-5">
                <div className="review-item text-center">
                  <StaticImage src="../../images/assets/vpe-logo.png" />
                </div>
              </div>
            </div>
            <div className="col mx-auto">
              <div className="pt-5">
                <div className="review-item text-center">
                  <StaticImage src="../../images/assets/nyj-logo.png" />
                </div>
              </div>
            </div>
            <div className="col mx-auto">
              <div className="pt-5">
                <div className="review-item text-center">
                  <StaticImage src="../../images/assets/s-logo.png" />
                </div>
              </div>
            </div>
          </div> */}
        </MySwiper>
      </div>
    </div>
  )
}

export default PartnersClient
