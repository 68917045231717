import React from "react"
import { Container } from "reactstrap"
import ContactForm from "../../components/form/form"

const ContactSection = () => {
  return (
    <div className="contact-section ">
      <Container className="h-100">
        <div className="row justify-content-center h-100">
          <div className="col-md-4 h-100">
            {" "}
            <h2 className="text-capitalize mt-0 mb-5">Have Any Questions?</h2>
            <p className="mb-3">Mail Us!</p>
            <a className="mb-3" href="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={18}
                className="mb-1 me-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              info@milehightraining.com
            </a>
            <p className="my-3">Call Us!</p>
            <a href="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={18}
                className="mb-1 me-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />{" "}
              </svg>
              +800-627-4510
            </a>
          </div>
          <div className="col-md-4 h-100">
            {" "}
            <div className="contact-inner">
              <ContactForm
                form_name="Newsletter Form"
                form_id="5bcd42f86b63453b251972bc"
                form_classname="form-newsletter"
              >
                <div className="form-row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        id="fullName"
                        placeholder="FullName"
                        required=""
                      />
                      {/* <label htmlFor="firstName">FullName</label> */}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="email"
                    id="homeEmail"
                    placeholder="Email address"
                    required=""
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    id="phone"
                    placeholder="Phone (optional)"
                    type="tel"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control h-auto"
                    id="message"
                    placeholder="Message"
                    rows="10"
                    required=""
                  />
                </div>
                <div className="form-group">
                  <div className="webriq-recaptcha" />
                </div>
                <div className="form-group mt-3">
                  <button className="btn-transparent-white" type="submit">
                    Send Message
                  </button>
                </div>
              </ContactForm>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ContactSection
