import React from "react"
import { Container } from "reactstrap"

const HeroSection = () => {
  return (
    <div className="hero-section bg-light">
      <Container className="h-100">
        <div className="row justify-content-center h-100">
          <div className="col-md-12 h-100">
            <div className="h-100 d-flex justify-content-center align-items-center">
              <div className="hero-inner text-center d-flex justify-content-center align-items-center">
                <div>
                  {" "}
                  <h1 className="text-white text-capitalize mb-0">
                    Pre-acclimate to altitude
                  </h1>
                  <p className="lead text-white mb-5">
                    Get your own altitude equipment now
                  </p>
                  <div className="d-lg-flex d-inline justify-content-center">
                    {" "}
                    <a href="#" className="btn-solid mx-2">
                      Buy Altitude Equipment
                    </a>
                    <a href="#" className="btn-transparent  mx-2">
                      Consult with Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HeroSection
