import React from "react"
import Swiper from "react-id-swiper"
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm"
import "react-id-swiper/lib/styles/css/swiper.css"
import { StaticImage } from "gatsby-plugin-image"

const ReviewSwiper = ({ children, slidesperview }) => {
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: slidesperview,
    spaceBetween: 30,
    containerClass: "swiper-container pb-5",
    nested: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // breakpoints: {
    //   768: {
    //     slidesPerView: 3,
    //     spaceBetween: 30
    //   },
    //   640: {
    //     slidesPerView: 2,
    //     spaceBetween: 20
    //   },
    //   320: {
    //     slidesPerView: 1,
    //     spaceBetween: 10
    //   }
    // }
  }
  return (
    <>
      <div className="review-swiper">
        {" "}
        <div className="container text-center">
          <h2 className="text-capitalize mb-5">What Our Clients Say</h2>
          <Swiper {...params} slidesPerView={1}>
            <div>
              {" "}
              <StaticImage src="../../images/client1.png" />
              <p className="client-name">J.K. Atlanta, GA </p>
              <p className="mx-auto" style={{ maxWidth: "44em" }}>
                Honestly, with the weather and altitude, there is no way we
                could have made it without your altitude tent. Only 7 people
                summited that day and the other 5 were “professional climbers”.
              </p>
            </div>
            <div>
              {" "}
              <StaticImage src="../../images/client1.png" />
              <p className="client-name">J.K. Atlanta, GA </p>
              <p className="mx-auto" style={{ maxWidth: "44em" }}>
                Honestly, with the weather and altitude, there is no way we
                could have made it without your altitude tent. Only 7 people
                summited that day and the other 5 were “professional climbers”.
              </p>
            </div>
          </Swiper>
          <div className="mt-5">
            {" "}
            <a href="#" className="btn-solid mx-2">
              View More
            </a>
            <a href="#" className="btn-transparent-blue mx-2">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

// class ReviewSwiper extends React.Component {
//   render() {
//     const { children, slidesperview } = this.props
//     const params = {
//       modules: [Pagination, Navigation],
//       slidesPerView: slidesperview,
//       spaceBetween: 30,
//       containerClass: "swiper-container pb-5",
//       nested: true,
//       autoplay: {
//         delay: 5000,
//         disableOnInteraction: false,
//       },
//       pagination: {
//         el: ".swiper-pagination",
//         type: "bullets",
//         clickable: true,
//       },
//       navigation: {
//         nextEl: ".swiper-button-next",
//         prevEl: ".swiper-button-prev",
//       },
//       // breakpoints: {
//       //   768: {
//       //     slidesPerView: 3,
//       //     spaceBetween: 30
//       //   },
//       //   640: {
//       //     slidesPerView: 2,
//       //     spaceBetween: 20
//       //   },
//       //   320: {
//       //     slidesPerView: 1,
//       //     spaceBetween: 10
//       //   }
//       // }
//     }
//     return <Swiper {...params}>{children}</Swiper>
//   }
// }

export default ReviewSwiper
