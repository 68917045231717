import React from "react"
import Layout from "../layout"
import { Container } from "reactstrap"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import MySwiper from "../../components/swiper/swiper"

const FeatureProduct = () => {
  return (
    <div className="container section-padding">
      <div className="section-heading text-center">
        {/* <h6 className="font-weight-bold text-uppercase flair">What's Your Goal?</h6> */}
        <h2 className="mb-0">Featured Products</h2>
        <p className="sub-heading mb-0">
          Buy, Rent, Or Customize Your Altitude Equipment Or Altitude Rooms
        </p>
      </div>
      <div className="container">
        <MySwiper slidesperview={1}>
          <div className="pt-0">
            <div className="review-item text-center">
              <StaticImage src="../../images/assets/featured-slide1.png" />
            </div>
          </div>

          <div className="pt-0">
            <div className="review-item text-center">
              <StaticImage src="../../images/assets/featured-slide2.png" />
            </div>
          </div>

          <div className="pt-0">
            <div className="review-item text-center">
              <StaticImage src="../../images/assets/featured-slide3.png" />
            </div>
          </div>
        </MySwiper>
        <div className="mt-5">
          <div className="d-flex justify-content-center align-items-center">
            <a href="#" className="btn-solid">
              View Our Popular Products
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureProduct
