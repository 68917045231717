import React from "react"
import Layout from "../layout"
import { Container } from "reactstrap"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import MySwiper from "../../components/swiper/swiper"

const Whatsyourgoal = () => {
  return (
    <div className="container section-padding" style={{ paddingBottom: "0" }}>
      <div className="section-heading text-center">
        {/* <h6 className="font-weight-bold text-uppercase flair">What's Your Goal?</h6> */}
        <h2 className="mb-0">What's Your Goal?</h2>
        <p className="sub-heading">
          Perfect Your Altitude Training By Preparing For Success For Any Goal
          And Application!
        </p>
      </div>
      <div className="container">
        <MySwiper slidesperview={1}></MySwiper>
        <div className="row">
          <div className="col-sm my-3">
            <div className="row">
              <div className="col-sm">
                <StaticImage
                  style={{
                    gridArea: "1/1",
                  }}
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Hero BG"
                  src="../../images/assets/Mountaineering.png"
                  formats={["auto", "webp", "avif"]}
                />
                <p
                  className="my-3 font-weight-bold"
                  style={{ fontSize: "22px" }}
                >
                  Mountaineering
                </p>
                <p>
                  Proven Method of Pre-acclimating before high altitude
                  exposure.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm my-3">
            <div className="row">
              <div className="col-sm">
                <StaticImage
                  style={{
                    gridArea: "1/1",
                  }}
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Hero BG"
                  src="../../images/assets/Sports Performance.png"
                  formats={["auto", "webp", "avif"]}
                />
                <p
                  className="my-3 font-weight-bold"
                  style={{ fontSize: "22px" }}
                >
                  Sports Performance
                </p>
                <p>
                  Proven Method of Pre-acclimating before high altitude
                  exposure.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm my-3">
            <div className="row">
              <div className="col-sm">
                <StaticImage
                  style={{
                    gridArea: "1/1",
                  }}
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Hero BG"
                  src="../../images/assets/Professional Teams.png"
                  formats={["auto", "webp", "avif"]}
                />
                <p
                  className="my-3 font-weight-bold"
                  style={{ fontSize: "22px" }}
                >
                  Professional Teams
                </p>
                <p>
                  Proven Method of Pre-acclimating before high altitude
                  exposure.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="row">
              <div className="col-sm my-3">
                <StaticImage
                  style={{
                    gridArea: "1/1",
                  }}
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Hero BG"
                  src="../../images/assets/Wellness.png"
                  formats={["auto", "webp", "avif"]}
                />
                <p
                  className="my-3 font-weight-bold"
                  style={{ fontSize: "22px" }}
                >
                  Welless
                </p>
                <p>
                  Proven Method of Pre-acclimating before high altitude
                  exposure.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" ">
          <p
            className="sub-heading text-center"
            style={{ paddingTop: "100px" }}
          >
            Want To Know More Which Equipment Or Which Room Conversion Is Best
            For You?
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <a href="#" className="btn-solid">
              Learn More
            </a>
          </div>
        </div>
      </div>
      <hr style={{ opacity: ".1", margin: "100px 0 0 " }} />
    </div>
  )
}

export default Whatsyourgoal
