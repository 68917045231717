import React from "react"
import Layout from "../layout"
import { Container } from "reactstrap"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const BlogResources = () => {
  return (
    <div className="blog-resources bg-light">
      <Container className="h-100">
        <div className="row justify-content-center h-100">
          <div className="col-md-12 h-100">
            <div className="h-100 d-flex justify-content-center align-items-center">
              <div className="text-center d-flex justify-content-center align-items-center">
                <div>
                  {" "}
                  <h2 className="text-capitalize mb-5">Blog and Resources</h2>
                  <p className="lead font-weight-bold">
                    Mile High Training ALTITUDE TO OXYGEN CHART
                  </p>
                  <p>The elevation related to the oxygen percentage.</p>
                  <StaticImage src="../../images/table.png" />
                  <div>
                    <a href="#" className="btn-solid">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default BlogResources
