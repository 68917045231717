import React from "react"
import Layout from "../layout"
import { Container } from "reactstrap"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
const ProvenBenifits = () => {
  return (
    <section style={{ backgroundColor: "rgb(241, 241, 241)" }}>
      <div className="container section-padding">
        <div className="section-heading text-center">
          <h2 className="mb-5">Proven Benefits Of Altitude Training</h2>
        </div>
        <div className="container">
          <div>
            <div className="">
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="row">
                    <div className="col-md-4">
                      {" "}
                      <div className=" text-center px-3">
                        <StaticImage src="../../images/assets/acftcoh-icon.svg" />
                        <p className="sub-heading mt-3">
                          Acclimatize from the comfort of Home
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <div className=" text-center px-3">
                        <StaticImage src="../../images/assets/ievmpo-icon.svg" />
                        <p className="sub-heading mt-3">
                          Improve Endurance, VO2 Max & Power Output
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className=" text-center px-3">
                        <StaticImage src="../../images/assets/arr-icon.svg" />
                        <p className="sub-heading mt-3">
                          Accelerate Recovery Rate
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5">
            <div className="">
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="text-center px-3">
                        <StaticImage src="../../images/assets/csvtac-icon.svg" />
                        <p className="sub-heading mt-3">
                          Cost savings vs. traditional “altitude camps”
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <div className="text-center px-3">
                        <StaticImage src="../../images/assets/rcfttr-icon.svg" />
                        <p className="sub-heading mt-3">
                          Re-condition faster than traditional rehab
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <div className="text-center px-3">
                        <StaticImage src="../../images/assets/awlantaatd-icon.svg" />
                        <p className="sub-heading mt-3">
                          Accelerate weight-loss and naturally treat asthma and
                          Type II Diabetes
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ opacity: ".1", margin: "0 0 70px " }} />
        <div className="section-heading text-center">
          <h2 className="mb-5">Who Uses / Benefits From Altitude Traning?</h2>
        </div>
        <div className="container">
          <div>
            <div className="">
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="text-center px-3">
                        <StaticImage src="../../images/assets/aoaa-icon.svg" />{" "}
                        <p className="sub-heading mt-3">
                          Athletes of all abilities
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <div className="text-center px-3">
                        <StaticImage src="../../images/assets/pst-icon.svg" />{" "}
                        <p className="sub-heading mt-3">
                          Pro Sports Teams (NFL, NBA, NHL, MLS)
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="text-center px-3">
                        <StaticImage src="../../images/assets/pgtafcov-icon.svg" />{" "}
                        <p className="sub-heading mt-3">
                          People going to altitude for climbing or vacationing
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="">
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="text-center px-3">
                        <StaticImage src="../../images/assets/pspc-icon.svg" />{" "}
                        <p className="sub-heading mt-3 px-3">
                          PT/ Rehab & Sports Performance Centers
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <div className="text-center px-3">
                        <StaticImage src="../../images/assets/hawfe-icon.svg" />{" "}
                        <p className="sub-heading mt-3 px-3">
                          Health and Wellness/ Fitness enthusiasts
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="text-center px-3">
                        <StaticImage src="../../images/assets/espurc-icon.svg" />{" "}
                        <p className="sub-heading mt-3 px-3">
                          Exercise Science/ Physiology University research
                          centers
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProvenBenifits
