import React from "react"
import Layout from "../layout"
import { Container } from "reactstrap"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const WorkHigh = () => {
  return (
    <section style={{ padding: "100px 15px" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <StaticImage
              className="img-fluid"
              src="../../images/assets/why-work.png"
            />
          </div>
          <div className="col-md-6 px-3 d-flex align-items-center">
            <div>
              <h2> Why Work With Mile High Training?</h2>
              <p>
                Our Altitude simulation equipment removes oxygen from the air,
                thus mimicking conditions at high altitudes. By using a mask,
                tent, chamber or room, anyone can reap the benefits of altitude
                training from any sea-level location and improve overall sport
                performance.
              </p>
              <p>
                We specialize in both personal-use equipment and
                commercial-grade high-flow air systems designed for altitude
                chambers and room conversions.
              </p>
              <div className="py-5">
                <a href="#" className="btn-solid ">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>{" "}
        <hr style={{ opacity: ".1", marginTop: "100px" }} />
      </div>
    </section>
  )
}

export default WorkHigh
