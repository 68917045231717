import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "react-lazy-load-image-component/src/effects/blur.css"

// Section Components
import Hero from "../components/sections/hero"
import BlogResources from "../components/sections/blog-resources"
import ReviewSwiper from "../components/swiper/review-swiper"
import ContactSection from "../components/sections/contact-section"
import Whatsyourgoal from "../components/sections/whats-your-goal"
import FeaturedProduct from "../components/sections/featured-product"
import ProvenBenifits from "../components/sections/proven-benefits"
import WorkHigh from "../components/sections/work-high-training"
import PartnersClient from "../components/sections/partners-client"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const posts = this.props.data.allSanityPost.edges
    const aboutPage = this.props.data.aboutPage
    const siteSettings = this.props.data.siteSettings

    const serializers = {
      types: {
        code: (props) => (
          <pre data-language={props.node.language}>
            <code>{props.node.code}</code>
          </pre>
        ),
      },
    }

    console.log(posts)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" description={siteDescription} />
        <Hero />
        <Whatsyourgoal />
        <FeaturedProduct />
        <ProvenBenifits />
        <WorkHigh />
        <PartnersClient />
        <ContactSection />
        <ReviewSwiper />
        <BlogResources />
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    siteSettings: sanitySiteSettings {
      title
      description
    }
    aboutPage: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
    }
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
